export  const prodItems=[
    {
        type:"iceCream",
        name:"A",
        price:1
    },
    {
        type:"iceCream",
        name:"B",
        price:2,
    },
    {
        type:"iceCream",
        name:"C",
        price:3,
    },

    {
        type:"iceCream",
        name:"D",
        price:4,
    },
    {
        type:"iceCream",
        name:"E",
        price:5,
    },
    {
        type:"iceCream",
        name:"F",
        price:6,
    },
    {
        type:"iceCream",
        name:"G",
        price:7,
    },
    {
        type:"iceCream",
        name:"H",
        price:8,
    },
    {
        type:"iceCream",
        name:"I",
        price:9,
    },
    {
        type:"iceCream",
        name:"J",
        price:10,
    },
];