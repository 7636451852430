import React from "react";

function Press(){
    return (
      <section id="press">
        <img class="press-logo" src="images/techcrunch.png" alt="tc-logo" />
        <img class="press-logo" src="images/tnw.png" alt="tnw-logo" />
        <img
          class="press-logo"
          src="images/bizinsider.png"
          alt="biz-insider-logo"
        />
        <img class="press-logo" src="images/mashable.png" alt="mashable-logo" />
      </section>
    );
}

export default Press;