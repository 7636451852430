import React from 'react'

function Academy() {
  return (
    <div>
      <h1>Hi! Welcome to Academy</h1>
      <a href='/'>Website</a>
    </div>
  )
}

export default Academy
